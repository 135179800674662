import React from "react"
import { Link } from "gatsby"
const InternshipJob = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img internship"
          data-bg="assets/images/banners/internship.jpg"
        >
          <div className="container">
            <div className="row"></div>
          </div>
        </div>

        {/* <!--======== careers-experts Start ==========--> */}
        <div className="careers-experts-wrapper section-space--pt_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 m-auto">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_30">
                  <h1 className="heading">
                    Our{" "}
                    <span className="text-color-primary">
                      {" "}
                      Internship Programs
                    </span>
                  </h1>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="ht-simple-job-listing move-up animate">
                  <div className="list">
                    <p className="pb-5 text-center">
                      No Internships are available right now.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--======== careers-experts End ==========--> */}

        <div
          className="container section-space--ptb_80"
          style={{ display: "none" }}
        >
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-6">
              <div className="contact-form-wrap">
                <h2 className="apply-now">Internship Program Apply Now</h2>
                <h5 id="internship-form" style={{ paddingTop: "40px" }}>
                  To apply for the internship positions, send your Resume at{" "}
                  <a href="mailto:careeers@aamanto.com">careeers@aamanto.com</a>{" "}
                  with job ID Reference.{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default InternshipJob
